
export default {
    // Emits on click state & text
    name: "Hashtag",
    props: {
        // Sets text color and calculates lighter background from it
        color: {
            type: String,
            default: "#000000",
        },
        // Use v-model to change active-state
        value: {
            type: Boolean,
            default: false,
        },
        // Optional: Disables state changing & clickability of the hashtag
        disabled: {
            type: Boolean,
            default: false,
        },
        // Optional: Overwrites the lighter variant of the text color for background
        activeColor: {
            type: String,
            default: undefined,
        },
        // Optional: Sets optional nuxt link for component
        link: {
            type: String,
            default: "",
        },
        // Optional: Sets box-shadow following mockup
        shadow: {
            type: Boolean,
            default: false,
        },
        // Optional: Makes chip small - for mobile
        small: {
            type: Boolean,
            default: false,
        },
        // Optional: Makes chip closeable - emits 'close' event
        close: {
            type: Boolean,
            default: false,
        },
        // Optional: Marks chip as selected
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        background() {
            if (!this.value) return ""
            if (this.activeColor) return this.activeColor
            return this.adjust(this.color, 35)
        },
        chipStyle() {
            return { border: "2px solid " + this.color }
        },
        colorStyle() {
            return { color: this.color }
        },
    },
    methods: {
        // adds transparency to HEX color - amount in HEX!
        adjust(color, amount) {
            return color + amount
        },
    },
}
